<template>
  <div>
    <div class="headerArea">
    <div class="logo"></div>
  </div>
  <landingPage />

  </div>
</template>

<script>
import landingPage from './components/landingPage.vue'


export default {
  name: 'App',
  components: {
    landingPage,
    
  }
}
</script>

<style>
@import 'bootstrap/dist/css/bootstrap.css';
@import 'bootstrap-vue/dist/bootstrap-vue.css';

#app {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  text-align: center;
  margin-top: 35px;
}
@media (max-width: 901px) {
  #app{
    margin-top:100px;
  }
}

.headerArea {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 72px;
    background: #0A3F88 0% 0% no-repeat;
    box-shadow: 0px 20px 20px #00000014;
    opacity: 1;
    z-index: 999;
}


.logo {
    top: 17px;
    left: 31px;
    width: 170px;
    height: 37px;
    position: absolute;
    background: transparent url('~@/assets/mainlogo.png') 0% 0% no-repeat padding-box;
    object-fit: scale-down;
    opacity: 1;
}

</style>
